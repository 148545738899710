import { useMemo } from 'react';

import {
	ConfluenceBlogpostAri,
	ConfluencePageAri,
	ConfluenceWhiteboardAri,
} from '@atlassian/ari/confluence';

export const useConfluencePageAri = (siteId: string, contentId: string, contentType: string) => {
	const pageAri = useMemo(() => {
		try {
			switch (contentType) {
				case 'page':
					return ConfluencePageAri.create({
						siteId,
						pageId: contentId,
					}).toString();
				case 'blogpost':
					return ConfluenceBlogpostAri.create({
						siteId,
						blogpostId: contentId,
					}).toString();
				case 'whiteboard':
					return ConfluenceWhiteboardAri.create({
						siteId,
						whiteboardId: contentId,
					}).toString();
			}
		} catch {
			return null;
		}
	}, [contentType, siteId, contentId]);
	return { pageAri };
};
