import React, { forwardRef, useCallback, useEffect, useRef } from 'react';

import { type ButtonProps } from '@atlaskit/button';
import { ChatPill, type ChatPillProps } from '@atlaskit/rovo-agent-components';
import {
	AI_MATE_ATTRIBUTES,
	type AIMateAttributeType,
	useAnalytics,
} from '@atlassian/conversation-assistant-instrumentation';

type AttributesRefType = {
	[AI_MATE_ATTRIBUTES.experienceId]?: AIMateAttributeType[typeof AI_MATE_ATTRIBUTES.experienceId];
	followUpIndex: number;
	followUpType: string;
	followUpLength: number;
};

type FollowUpButtonProps = ChatPillProps &
	Omit<AttributesRefType, 'followUpLength'> & {
		followUpText: string;
		onClick: () => void;
	};

export const FollowUpButton = forwardRef<HTMLButtonElement, FollowUpButtonProps>(
	(
		{ onClick: onButtonClick, experienceId, followUpIndex, followUpType, followUpText, ...props },
		ref,
	) => {
		const { sendAnalyticsEvent } = useAnalytics();

		const analyticsPropertiesRef = useRef<AttributesRefType>();
		analyticsPropertiesRef.current = {
			followUpIndex,
			followUpType,
			followUpLength: followUpText.length,
			...(experienceId ? { [AI_MATE_ATTRIBUTES.experienceId]: experienceId } : {}),
		};

		useEffect(() => {
			sendAnalyticsEvent({
				eventType: 'screen',
				name: 'followUpQueryButton',
				attributes: analyticsPropertiesRef.current,
			});
		}, [sendAnalyticsEvent]);

		const onClick: NonNullable<ButtonProps['onClick']> = useCallback(
			(_, analyticsEvent) => {
				sendAnalyticsEvent(
					analyticsEvent.update({
						actionSubjectId: 'followUpQueryButton',
						attributes: {
							...analyticsEvent.payload.attributes,
							...analyticsPropertiesRef.current,
						},
					}),
				);

				onButtonClick();
			},
			[onButtonClick, sendAnalyticsEvent],
		);

		return (
			<ChatPill onClick={onClick} {...props} ref={ref}>
				{followUpText}
			</ChatPill>
		);
	},
);

export { ChatPill };
