/**
 * NOTE: Some of these fields are used to track AI MAU
 * See this page for more details: https://hello.atlassian.net/wiki/spaces/AAI/pages/3770721410/Unified+event+Instrumentation+for+AI
 */

export const AI_MATE_ATTRIBUTES = {
	agentCreatorType: 'agentCreatorType',
	agentId: 'agentId',
	agentVisibility: 'agentVisibility',
	agentExternalConfigReference: 'agentExternalConfigReference',
	agentIsDefault: 'agentIsDefault',
	conversationChannelId: 'conversationChannelId',
	experienceId: 'experienceId',
	fullScreen: 'fullScreen',
	product: 'product',
	singleInstrumentationID: 'singleInstrumentationID',
} as const;

export type AIMateAttributeType = {
	[AI_MATE_ATTRIBUTES.agentCreatorType]: string | null;
	[AI_MATE_ATTRIBUTES.agentId]: string | null;
	[AI_MATE_ATTRIBUTES.agentVisibility]: string | null;
	[AI_MATE_ATTRIBUTES.agentExternalConfigReference]: string | null;
	[AI_MATE_ATTRIBUTES.agentIsDefault]: boolean | null;
	[AI_MATE_ATTRIBUTES.conversationChannelId]: string;
	[AI_MATE_ATTRIBUTES.experienceId]: string;
	[AI_MATE_ATTRIBUTES.fullScreen]: boolean;
	[AI_MATE_ATTRIBUTES.product]: string;
	[AI_MATE_ATTRIBUTES.singleInstrumentationID]: string;
};

export type AnalyticsPropertiesContextProps = {
	propertiesRef: React.MutableRefObject<AIMateAttributeType>;
	setAnalyticsPropertiesWithoutRerender: (properties: Partial<AIMateAttributeType>) => void;
};
