import { useCallback } from 'react';

import {
	type AnalyticsEventPayload,
	UIAnalyticsEvent,
	useAnalyticsEvents,
} from '@atlaskit/analytics-next';

import { useAnalyticsPropertiesContext } from './analytics-properties-context';
import { AI_MATE_ATTRIBUTES } from './analytics-properties-context/types';
import { ANALYTICS_CHANNEL } from './constants';
import { type AIMAUEventPayload, type TrackAIEventPayloads } from './types';
import { getAttributesFromContexts } from './utils';

const isTownSquareEvent = () =>
	['home.atlassian.com', 'team.atlassian.com'].some((host) => window.location?.host.includes(host));

const DEFAULT_TAGS: string[] = ['atlaskit', 'aiMate'];

/**
 * A wrapper around the analytics-next hook to send analytics events to the ai mate channel
 */
export const useAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { propertiesRef } = useAnalyticsPropertiesContext();

	// TODO: Improve this functionality, potentially with a higher order provider for tags or similar
	// This is a temporary solution to add the 'townsquare' tag to operational events so Experience Tracker works in Atlas
	const maybeAtlasOperationalTags = useCallback(
		(inputPayload: AnalyticsEventPayload | UIAnalyticsEvent) => {
			const payload =
				inputPayload instanceof UIAnalyticsEvent ? inputPayload.payload : inputPayload;
			if (
				payload.actionSubject === 'ui' &&
				payload.eventType === 'operational' &&
				isTownSquareEvent()
			) {
				return ['townsquare'];
			}
			return [];
		},
		[],
	);

	const getDefaultAttributes = useCallback(() => {
		const {
			agentCreatorType,
			agentId,
			agentVisibility,
			agentExternalConfigReference,
			agentIsDefault,
			conversationChannelId,
			experienceId,
			fullScreen,
			product,
		} = propertiesRef.current;
		return {
			[AI_MATE_ATTRIBUTES.agentCreatorType]: agentCreatorType,
			[AI_MATE_ATTRIBUTES.agentId]: agentId,
			[AI_MATE_ATTRIBUTES.agentVisibility]: agentVisibility,
			[AI_MATE_ATTRIBUTES.conversationChannelId]: conversationChannelId,
			[AI_MATE_ATTRIBUTES.experienceId]: experienceId,
			[AI_MATE_ATTRIBUTES.fullScreen]: fullScreen,
			[AI_MATE_ATTRIBUTES.product]: product,
			[AI_MATE_ATTRIBUTES.singleInstrumentationID]: conversationChannelId,
			[AI_MATE_ATTRIBUTES.agentExternalConfigReference]: agentExternalConfigReference,
			[AI_MATE_ATTRIBUTES.agentIsDefault]: agentIsDefault,
		};
	}, [propertiesRef]);

	const trackAIMAUEvent = useCallback(
		(payload: TrackAIEventPayloads) => {
			// Handle tags
			const tags = [...DEFAULT_TAGS];
			if (isTownSquareEvent()) {
				tags.push('townsquare');
			}

			const {
				source,
				subjectId,
				proactive,
				userGenerated,
				messageId,
				messageActions,
				singleInstrumentationID,
				timestamp,
				is3PLink,
			} = payload;

			let attributes: AIMAUEventPayload['attributes'] = {
				...getDefaultAttributes(),
				isAIFeature: 1,
				proactiveAIGenerated: proactive ? 1 : 0,
				userGeneratedAI: userGenerated ? 1 : 0,
				aiFeatureName: 'aiChat',
				is3PLink,
			};

			if (messageId) {
				attributes.messageId = messageId;
			}

			if (messageActions) {
				attributes.messageActions = messageActions;
			}

			if (timestamp) {
				attributes.timestamp = timestamp;
			}

			if (singleInstrumentationID) {
				attributes.singleInstrumentationID = singleInstrumentationID;
			}

			// Core AI MAU payload
			let eventPayload: Omit<AIMAUEventPayload, 'actionSubject' | 'action'> | AIMAUEventPayload = {
				eventType: 'track',
				tags,
				source,
				actionSubjectId: subjectId,
				attributes,
			};

			// Handle specific AI MAU events
			switch (payload.type) {
				case 'initiated':
					eventPayload = {
						...eventPayload,
						actionSubject: 'aiInteraction',
						action: 'initiated',
					};
					break;
				case 'viewed':
					eventPayload = {
						...eventPayload,
						actionSubject: 'aiResult',
						action: 'viewed',
					};
					break;
				case 'error':
					eventPayload = {
						...eventPayload,
						actionSubject: 'aiResult',
						action: 'error',
					};
					break;
				case 'dismissed':
					eventPayload = {
						...eventPayload,
						actionSubject: 'aiInteraction',
						action: 'dismissed',
					};
					break;
				case 'actioned':
					eventPayload = {
						...eventPayload,
						actionSubject: 'aiResult',
						action: 'actioned',
						attributes: {
							...eventPayload.attributes,
							aiResultAction: payload.actionResult,
						},
					};
					break;
				case 'feedback':
					eventPayload = {
						...eventPayload,
						actionSubject: 'aiFeedback',
						action: 'submitted',
						attributes: {
							...eventPayload.attributes,
							aiFeedbackResult: payload.feedbackResult,
						},
					};
					break;
			}

			// Build event and apply context attributes
			const event = createAnalyticsEvent(eventPayload);
			const aiMateContextAttributes = getAttributesFromContexts(event.context);

			// Fire
			event
				.update({
					attributes: {
						...event.payload.attributes,
						...aiMateContextAttributes,
					},
				})
				.fire(ANALYTICS_CHANNEL);
		},
		[createAnalyticsEvent, getDefaultAttributes],
	);

	const sendAnalyticsEvent = useCallback(
		(
			payload: AnalyticsEventPayload | UIAnalyticsEvent,
			options?: {
				removeDefaultTags?: boolean;
			},
		) => {
			const extraTags = options?.removeDefaultTags ? [] : DEFAULT_TAGS;
			const allTags = Array.from(
				new Set([
					...extraTags,
					...((payload as any).tags || []),
					...maybeAtlasOperationalTags(payload),
				]),
			);

			const defaultAttributes = getDefaultAttributes();

			if (payload instanceof UIAnalyticsEvent) {
				const aiMateContextAttributes = getAttributesFromContexts(payload.context);

				payload
					.update({
						...payload.payload,
						tags: allTags,
						attributes: {
							...defaultAttributes,
							...payload.payload.attributes,
							...aiMateContextAttributes,
						},
					})
					.fire(ANALYTICS_CHANNEL);
			} else {
				const event = createAnalyticsEvent({
					...payload,
					tags: allTags,
					attributes: {
						...defaultAttributes,
						...payload.attributes,
					},
				});

				const aiMateContextAttributes = getAttributesFromContexts(event.context);
				event
					.update({
						attributes: {
							...event.payload.attributes,
							...aiMateContextAttributes,
						},
					})
					.fire(ANALYTICS_CHANNEL);
			}
		},
		[createAnalyticsEvent, maybeAtlasOperationalTags, getDefaultAttributes],
	);

	return {
		sendAnalyticsEvent,
		trackAIMAUEvent,
	};
};
