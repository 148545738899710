const WHITEBOARD_VALIDATION_REGEX = /^###\s.*\n(?:.*\n)*####\sKey\sTakeaways:(?:\n-\s.*)+$/;

function validateWhiteboardMarkdown(markdown: string): boolean {
	return WHITEBOARD_VALIDATION_REGEX.test(markdown);
}

export function validateMarkdownByContentType(
	contentType: string,
	markdown: string,
): boolean | undefined {
	switch (contentType) {
		case 'whiteboard':
			return validateWhiteboardMarkdown(markdown);
		default:
			return undefined;
	}
}
