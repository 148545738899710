import { useState, useEffect } from 'react';

import type { SummaryAnalyticsPropertiesType } from '../shared-components/summarySharedTypes';

type SummaryAnalyticsProperties = {
	analyticsProperties: SummaryAnalyticsPropertiesType;
};

export const useQuickSummaryAnalyticsProperties = (
	contentLastUpdated: number,
	contentType: string,
): SummaryAnalyticsProperties => {
	const [analyticsProperties, setAnalyticsProperties] = useState<SummaryAnalyticsPropertiesType>({
		source: 'quickSummary',
	});

	useEffect(() => {
		setAnalyticsProperties((prev) => {
			return {
				...prev,
				additionalAnalyticsAttributes: {
					contentLastUpdated,
					contentType,
				},
			};
		});
	}, [contentLastUpdated, contentType]);

	return { analyticsProperties };
};
