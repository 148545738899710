import React from 'react';

import type { FollowUpObject } from '@atlassian/ai-summary';

import { QuickSummaryFollowUpComponent } from './QuickSummaryStreaming/QuickSummaryFollowUpComponent';

type QuickSummaryFollowUpContainerProps = {
	isPageSummaryFollowUpEnabled: boolean;
	content: string;
	contentPath: string;
	followUpContent?: FollowUpObject[];
	setIsOpen: (open: boolean) => void;
};

export const QuickSummaryFollowUpContainer = ({
	isPageSummaryFollowUpEnabled,
	followUpContent,
	content,
	contentPath,
	setIsOpen,
}: QuickSummaryFollowUpContainerProps) => {
	if (!isPageSummaryFollowUpEnabled) {
		return null;
	}

	if (!followUpContent) {
		return null;
	}

	return (
		<QuickSummaryFollowUpComponent
			followUpContent={followUpContent}
			summaryContent={content}
			contentPath={contentPath}
			setIsOpen={setIsOpen}
		/>
	);
};
