import React from 'react';

import { AISummaryStreamingState } from '@atlassian/ai-summary';

import { ExperienceSuccess, type ExperienceName } from '@confluence/experience-tracker';

type ExperienceStreamingSuccessProps = {
	state: AISummaryStreamingState;
	experienceName: ExperienceName;
};

export const ExperienceStreamingSuccess = ({
	state,
	experienceName,
}: ExperienceStreamingSuccessProps) => {
	if (
		state === AISummaryStreamingState.FinalResponse ||
		state === AISummaryStreamingState.FollowUp
	) {
		return <ExperienceSuccess name={experienceName} />;
	}

	return null;
};
