import React, { type FC } from 'react';

import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { Box, type BoxProps, Inline, xcss } from '@atlaskit/primitives';

import { GeneratingStreamAnimation } from '../../common/assets/GeneratingStreamAnimation';

const styles = xcss({ color: 'color.text.subtle' });

const i18n = defineMessages({
	generatingSummaryText: {
		id: 'ai-stream-loading.title',
		defaultMessage: 'Atlassian Intelligence is working...',
		description: 'Text for loading state of streaming',
	},
	generatingSummaryAnimationAlt: {
		id: 'ai-stream-loading.generation-animation',
		defaultMessage: 'Generating',
		description: 'Alt text of the generation animation',
	},
});

type AIStreamLoadingProps = {
	padding?: BoxProps<'div'>['padding'];
};

export const AIStreamLoading: FC<AIStreamLoadingProps> = ({ padding = 'space.300' }) => {
	const { formatMessage } = useIntl();

	return (
		<Box padding={padding} xcss={styles}>
			<Inline alignBlock="center" space="space.025">
				<GeneratingStreamAnimation alt={formatMessage(i18n.generatingSummaryAnimationAlt)} />
				<FormattedMessage {...i18n.generatingSummaryText} />
			</Inline>
		</Box>
	);
};
