import { type RefObject, useEffect, useState } from 'react';

export const useOnScreen = (ref: RefObject<HTMLElement | null>) => {
	const [isIntersecting, setIntersecting] = useState(false);
	useEffect(() => {
		if (process.env.REACT_SSR || !ref.current) {
			return;
		}
		const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));
		if (ref.current) {
			observer.observe(ref.current);
		}
		return () => {
			if (observer) {
				observer.disconnect();
			}
		};
	});
	return isIntersecting;
};
