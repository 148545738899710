import { type RefObject, useEffect, useRef } from 'react';

import { useOnScreen } from '../use-on-screen';

export const usePopupDwellTime = (ref: RefObject<HTMLElement | null>, interval: number = 500) => {
	const isOnScreen = useOnScreen(ref);

	const dwellTimeRef = useRef(0);

	useEffect(() => {
		const incremental = setInterval(() => {
			if (isOnScreen) {
				dwellTimeRef.current += interval;
			}
		}, interval);

		return () => {
			clearInterval(incremental);
		};
	}, [isOnScreen, interval]);

	const getDwellTime = () => dwellTimeRef.current;
	return { getDwellTime };
};
